.main {
  margin-top: 15vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  background-color: #dcb226;
  position: relative;
  overflow: hidden;
}

.mainEn {
  padding: 3rem 0 1rem 3rem;
}

.mainAr {
  padding: 3rem 3rem 1rem 0;
}

.up {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.contactTitle {
  display: flex;
  flex-direction: column;
}

.div2 {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.contact-form {
  width: 45%;
  display: flex;
}

.contact-form h3 {
  padding: 0.5rem;
  font-size: 20px;
}

.formDiv {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
}

.form {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.formFilds {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.fild {
  width: 50%;
  display: flex;
  flex-direction: column;
  padding: 0.5rem;
}

.fild1 {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 0.5rem;
}

.form-i {
  border: 1px gray solid;
  border-radius: 5px;
  margin-top: 0.5rem;
  padding: 0.7rem;
  outline: none;
}

.form-i:focus {
  border: 1.5px solid #2c73eb;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.fild1 .form-i {
  resize: none;
}

.text-success {
  font-size: 13px;
  color: rgb(8, 106, 8);
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
  text-align: start;
}

.btn {
  border: 1px gray solid;
  margin: 0.5rem;
  padding: 0.5rem 2rem;
  border-radius: 5px;
  color: #ffff;
  background-color: #e02229;
}

.btn:hover {
  background-color: #b0171d;
}

.backImage {
  margin: 0.5rem;
  margin-left: 0;
  padding-left: 0;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: end;
}

.contactUs {
  width: 30%;
  height: 100%;
  background-color: #ffffff82;
  border-radius: 10px;
  padding: 1rem;
  z-index: 4;
}

.items {
  display: flex;
  flex-direction: column;
}

.contactDesc {
  padding-top: 1rem;
  font-size: 16px;
  font-weight: 600;
}

.backImage1 {
  display: none;
}

.backImage:nth-child(1) {
  z-index: 0;
}

.backImage:nth-child(2) {
  z-index: 1;
}

.backimg {
  width: 40%;
  position: absolute;
}

.backimg img {
  width: 100%;
}

.item {
  width: 100%;
  display: flex;
  align-items: start;
}

.item h4 {
  width: 90%;
  padding: 0.3rem;
  font-size: 15px;
  font-weight: 500;
  display: flex;
  justify-content: flex-start;
}

.item img {
  width: 20px;
  height: 20px;
  margin: 0.5rem;
}

.teejan {
  width: 200px;
  padding: 2rem;
  position: relative;
}

.Mobile2 {
  color: #000;
  text-decoration: none;
  display: flex;
  align-items: start;
}

@media only screen and (max-width: 768px) {
  .main {
    margin-top: 5vh;
    padding: 1rem;
  }

  .contact-form {
    width: 100%;
  }

  .contact-form h3 {
    font-size: 13px;
  }

  .contactUs {
    width: 100%;
    margin-bottom: 2rem;
  }

  .up {
    display: flex;
    flex-direction: column;
  }

  .formFilds {
    display: flex;
    flex-direction: column;
  }

  .div2 {
    display: flex;
    flex-direction: column;
  }

  .form {
    margin-bottom: 2rem;
  }

  .fild,
  .fild1 {
    width: 100%;
  }

  .backImage {
    width: 100%;
    margin: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .backimg {
    width: 100%;
    margin-top: 0.5rem;
    display: flex;
    justify-content: center;
  }

  .backimg img {
    width: 300px;
  }

  .backImage1 {
    display: block;
  }

  .backImageimg {
    display: none;
  }

  .teejan {
    width: 100px;
    padding: 1rem;
  }
}

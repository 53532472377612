
.header {
  width: 100%;
  height: 20vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 3;
}

.navbar {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.px-2 {
  padding-right: 0.5rem;
  padding-left: 0.5rem;
}

.banner {
  width: 100%;
  height: 5vh;
  padding: 0 2rem;
  background-color: rgba(40, 20, 28, 1);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  overflow: hidden;
}

.icons {
  width: 50%;
  margin-left: 3rem;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.banar-icon {
  width: 20px;
  height: 20px;
  margin-left: 0.5rem;
}

.banner a {
  text-decoration: none;
  color: #d3d3d3;
}

.nav-nav {
  width: 100%;
  padding: 1rem 0;
  color: rgba(40, 20, 28, 1);
  background-color: rgba(255, 255, 255, 1);
  border-radius: 0 0 20px 20px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: center;
  align-items: center;
}

.navbar-nav {
  width: 40%;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

.nav-link {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.main-color {
  color: rgba(40, 20, 28, 1);
}

.red {
  color: rgb(202, 18, 18);
}

.nav-item a {
  text-decoration: none;
}

.h-img {
  width: 20px;
  height: 20px;
}

@media only screen and (max-width: 768px) {
  .header {
    height: 15vh;
  }

  .icons {
    display: none;
  }

  .navbar-nav {
    width: 90%;
    padding: 0 1rem;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
  }

  .h-img {
    width: 18px;
    height: 18px;
  }

  .nav-link p {
    font-size: 12px;
  }
}

.servicesBack {
  width: 100%;
  margin-top: 6rem;
  height: 200vh;
  display: flex;
  position: absolute;
}

.yellowBlur {
  width: 100%;
  background-image: url('../assets/YellowBlur.svg');
  background-size: contain;
  background-repeat: no-repeat;
  position: relative;
  animation: leftMove 10s ease-out infinite;
}

.redBlur {
  width: 100%;
  background-image: url('../assets/RedBlur.svg');
  background-size: contain;
  background-repeat: no-repeat;
  position: relative;
  animation: rightMove 10s ease-out infinite;
}

.servicesBack:nth-child(1) {
  z-index: -1;
}

.servicesBack:nth-child(2) {
  z-index: 0;
}

.Services {
  position: relative;
  padding: 3rem;
  padding-top: 10rem;
  height: auto;
}

.carosuel {
  display: none;
}

@keyframes leftMove {
  from { left: -1000px; }
  to { left: 1000px; }
}

@keyframes rightMove {
  from { right: -1000px; }
  to { right: 1000px; }
}

.title {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: start;
}

.title h2 {
  font-weight: bold;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}

.Services-images {
  width: 100%;
  margin: 2rem;
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.serviseDiv {
  filter:
    invert(35%)
    opacity(50%);
  padding: 0.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.p-2 {
  padding: 0.5rem;
}

.serviseImg {
  width: 80px;
  height: 80px;
}

.serviseDiv:hover .serviseImg,
.activeImg {
  width: 90px;
  height: 90px;
}

.serviseDiv:hover,
.active {
  filter: invert(0%);
}

.detils {
  width: 100%;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
}

.mainData {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.ServicesIcon {
  width: 20%;
  margin: 1rem;
  display: flex;
  justify-content: end;
}

.ServicesIcon img {
  width: 50%;
  height: 50%;
}

.ServicesDesc {
  width: 80%;
  display: flex;
  flex-direction: column;
  justify-items: start;
  align-items: flex-start;
}

.decktop {
  width: 100%;
  display: block;
}

.ServicesDesc h3 {
  font-weight: bold;
  padding: 1rem;
}

.ServicesDesc p {
  width: 80%;
  padding: 1rem;
}

.data {
  width: 80%;
  padding-right: 12rem;
  display: flex;
  align-items: start;
  justify-content: center;
}

.checkIcon {
  font-size: 30px;
  color: #dcb226;
}

.data-dtl {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: start;
}

.data-dtl h6 {
  width: 100%;
  padding: 0 1rem;
  font-size: 20px;
  font-weight: bold;
}

.data-dtl p {
  width: 80%;
  padding: 1rem;
  font-size: 15px;
}

@media only screen and (max-width: 768px) {
  .servicesBack {
    width: 300%;
    top: 0;
    height: 100%;
    display: flex;
    position: fixed;
    background-repeat: repeat-x;
    z-index: -2;
  }

  .yellowBlur {
    background-size: cover;
    animation: leftMove 10s ease-out infinite;
    position: relative;
    z-index: 4;
  }

  .redBlur {
    background-size: cover;
    animation: rightMove 10s ease-out infinite;
    position: relative;
    z-index: 1;
  }

  @keyframes leftMove {
    from { left: -500px; }
    to { left: 500px; }
  }

  @keyframes rightMove {
    from { right: -500px; }
    to { right: 500px; }
  }

  .title {
    display: flex;
    justify-content: center;
  }

  .title img {
    width: 40px;
  }

  .Services-images {
    width: 200%;
    display: flex;
    align-items: center;
    justify-content: space-around;
  }

  .Services {
    width: 100%;
    padding: 3rem 0;
  }

  .carosuel {
    direction: rtl;
    display: block;
    width: 95%;
    align-items: center;
    padding-right: 2rem;
    padding-left: 2rem;
  }

  .decktop {
    display: none;
  }

  .inner-carosuel {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }

  .serviseImg {
    width: 50px;
    height: 50px;
  }

  .serviseDiv:hover .serviseImg,
  .activeImg {
    width: 70px;
    height: 70px;
  }

  .detils {
    width: 100%;
    padding: 0 2rem;
  }

  .mainData {
    display: flex;
    flex-direction: column;
  }

  .ServicesIcon {
    width: 25%;
    display: none;
  }

  .ServicesIcon img {
    width: 100%;
    height: 100%;
  }

  .ServicesDesc {
    width: 100%;
  }

  .ServicesDesc h3 {
    width: 100%;
    font-size: 18px;
    font-weight: bold;
    text-align: center;
    display: none;
  }

  .ServicesDesc p {
    width: 100%;
    padding: 0.5rem 2rem 1rem 1rem;
    font-size: 13px;
    font-weight: 400;
  }

  .data {
    width: 100%;
    padding: 0;
  }

  .data-dtl h6 {
    font-size: 12px;
    font-weight: bold;
  }

  .data-dtl p {
    width: 100%;
    padding: 0.5rem;
    font-size: 12px;
  }

  .checkIcon {
    font-size: 20px;
  }
}

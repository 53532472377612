.ourClint {
  padding: 3rem;
  padding-bottom: 0;
  display: flex;
}

@keyframes slide {
  from {
    transform: translateX(100%);
  }

  to {
    transform: translateX(0);
  }
}

.logos {
  direction: rtl;
  margin-top: 20px;
  overflow: hidden;
  padding: 30px 0;
  background-color: rgba(255, 255, 255, 1);
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  white-space: nowrap;
  position: relative;
}

.logos::before,
.logos::after {
  position: absolute;
  top: 0;
  width: 250px;
  height: 100%;
  content: "";
  z-index: 2;
}

.logos::before {
  left: 0;
  background: linear-gradient(to left, rgba(255, 255, 255, 0), white);
}

.logos::after {
  right: 0;
  background: linear-gradient(to right, rgba(255, 255, 255, 0), white);
}

.logos-slide {
  display: inline-block;
  animation: 40s slide infinite linear;
}

.logos:hover .logos-slide {
  animation-play-state: paused;
}

.logos-slide img {
  margin: 0 64px;
}

@media only screen and (max-width: 768px) {
  .ourClint {
    padding: 0;
  }

  .logos-slide img {
    width: 90px;
    height: 50px;
    margin: 0 44px;
  }

  .logos::before,
  .logos::after {
    position: absolute;
    top: 0;
    width: 250px;
    height: 100%;
    content: "";
    z-index: 2;
  }

  .logos::before {
    left: 0;
    background: none;
  }

  .logos::after {
    right: 0;
    background: none;
  }
}

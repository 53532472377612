.footer {
  width: 100%;
  margin-top: 5rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.line {
  border-bottom: 2px solid black;
  width: 40%;
}

.footer h6 {
  padding: 0;
  font-size: 18px;
}

@media only screen and (max-width: 768px) {
  .footer h6 {
    width: 20%;
    padding: 0.1rem;
    font-size: 9px;
  }
}

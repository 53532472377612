swiper-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

swiper-slide {
  width: 40%;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

swiper-slide img {
  width: 30%;
  object-fit: contain;
}

/*
.nav-btn {
  display: block;
  position: absolute;
  z-index: 2;
  user-select: none;
  -webkit-user-select: none;
  background-color: rgba(0, 0, 0, 0.3);
  cursor: pointer;
  padding: 0.2rem;
  border-radius: 5px;
  transition: background 0.5s ease;
}

.nav-btn:hover {
  background-color: #dcb226;
}

.nav-btn.custom-prev-button {
  top: 12%;
  left: 2em;
}

.nav-btn.custom-next-button {
  right: 2em;
  top: 12%;
} */

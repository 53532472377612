.back {
  width: 100%;
  top: 3vh;
  height: 130vh;
  display: flex;
  background-image: url('../assets/pgImg.svg');
  background-size: contain;
  background-repeat: no-repeat;
  position: absolute;
  z-index: -2;
}

.transform {
  transform: scaleX(-1);
}

.blur {
  width: 100%;
  background-image: url('../assets/BlueBlur.svg');
  background-size: contain;
  background-repeat: no-repeat;
  position: relative;
  animation: leftMove 10s ease-out infinite;
  z-index: -2;
}

@keyframes leftMove {
  from { left: -100px; }
  to { left: 1000px; }
}

.infos {
  width: 50%;
  top: 17vh;
  padding: 2rem;
  position: relative;
  height: 90vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.infos h2 {
  width: 75%;
  font-size: 45px;
  text-align: center;
  overflow: hidden;
  position: relative;
  z-index: 1;
}

.infos p {
  width: 75%;
  font-size: 20px;
  text-align: center;
  padding-bottom: 0.5rem;
}

.logo {
  width: 130px;
  height: 130px;
  overflow: hidden;
  position: relative;
  z-index: 2;
}

.underImg {
  width: 100%;
}

@media only screen and (max-width: 768px) {
  .back {
    width: 200%;
    height: 100vh;
    top: 2rem;
    background-image: none;
    background-color: #f5f5f5;
    position: fixed;
    z-index: -3;
  }

  .blur {
    width: 50%;
    background-size: contain;
    animation: leftMove 10s ease-out infinite;
    z-index: -1;
    display: none;
  }

  @keyframes leftMove {
    from { left: -500px; }
    to { left: 500px; }
  }

  .infos {
    width: 100%;
    top: 6rem;
    height: 65vh;
    padding: 3rem;
    display: flex;
    justify-content: start;
  }

  .logo {
    width: 100px;
    height: 100px;
  }

  .infos h2 {
    width: 100%;
    font-size: 30px;
  }

  .infos p {
    width: 100%;
    font-size: 16px;
  }
}

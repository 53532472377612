@import './Styles/Header.css';
@import './Styles/About.css';
@import './Styles/ourCints.css';
@import './Styles/servise.css';
@import './Styles/ContactMe.css';
@import './Styles/Footer.css';
@import './Styles/swiperImg.css';

@font-face {
  font-family: 'FrutBold';
  src: url('./assets/fonts/FrutigerLTArabic65Bold.ttf');
}

@font-face {
  font-family: 'FrutBlack';
  src: url('./assets/fonts/frutigerltarabic75black.ttf');
}

@font-face {
  font-family: 'FrutRoman';
  src: url('./assets/fonts/FrutigerLTArabic55Roman.ttf');
}

@font-face {
  font-family: 'LatoRoman';
  src: url('./assets/fonts/Lato-Regular.ttf');
}

.fontBold {
  font-family: 'FrutBold', sans-serif;
}

.fontBlack {
  font-family: 'FrutBlack', sans-serif;
}

.fontRoman {
  font-family: 'FrutRoman', sans-serif;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  overflow-x: hidden;
}

.rtl {
  direction: rtl;
  font-family: 'FrutRoman', sans-serif;
}

.En {
  font-family: 'LatoRoman', sans-serif;
}

p {
  overflow: hidden;
}

.sec {
  width: 100%;
  overflow: hidden;
}
